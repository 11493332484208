'use client';

import { ArrowLeftOutlined } from '@packages/icons-react';
import type { FC, ReactNode } from 'react';
import Image from 'next/legacy/image';
import { cn } from 'class-merge';
import images from '~build-config/images.json';
import SelectLanguageWidget from '~components/widget/select-language-widget';
import appConfig from '~build-config/config.json';

export interface UnAuthorizedLayoutProps {
  logo?: ReactNode;
  children: ReactNode;
  onBackward?: () => void;
  locale: string;
}

const UnAuthorizedLayout: FC<UnAuthorizedLayoutProps> = ({
  logo = (
    <Image
      className="object-contain"
      height={128}
      src={images.horizontalLogo}
      unoptimized
      width={210}
    />
  ),
  children,
  onBackward,
  locale,
}) => {
  return (
    <div
      className={cn(
        'bg-layout relative flex min-h-screen flex-col items-center px-8 py-16 text-white sm:px-16',
        appConfig.imageCoverUnauthorized
          ? 'overflow-hidden sm:py-24'
          : 'sm:py-36',
      )}
    >
      <SelectLanguageWidget
        className="absolute right-8 top-8 z-50 sm:right-10 sm:top-8"
        locale={locale}
        optionClassName="bg-black -mt-2"
      />
      <div className="flex h-[128px] w-[384px] justify-center">{logo}</div>
      {onBackward ? (
        <div
          aria-hidden
          className="bg-color-button text-color absolute left-10 top-10 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-lg text-xs"
          onClick={onBackward}
          role="button"
          tabIndex={-1}
        >
          <ArrowLeftOutlined />
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default UnAuthorizedLayout;
