import appConfig from '~build-config/config.json';

export enum SizeType {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export enum ButtonColors {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  TRANSPARENT = 'transparent',
  EARTH = 'earth',
  PURPLE = 'purple',
}

export enum ButtonVariants {
  FILL = 'fill',
  GHOST = 'ghost',
}

export enum CheckboxColor {
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export enum NoteColor {
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
  GHOST = 'ghost',
}

export const DateFormat = 'DD/MM/YYYY, HH:mm';
export const MoneyWithDecimalFormat = '0,0.[00]';
export const THBFormat = MoneyWithDecimalFormat;
export const VNDFormat = '0,0';
export const VND1000Format = '0,0.000';
export const DEFAULT_LANGUAGE = appConfig.i18n.fallbackLng;

export enum LottoRedirectPathEnum {
  BetTransaction = 'bet-transactions',
  LottoPrize = 'lotto-prize',
}

export enum CurrencyEnum {
  THB = 'THB',
  VND = 'VND',
}

export enum CountryEnum {
  TH = 'th',
  VI = 'vi',
  EN = 'en',
  VN = 'vn',
}

export const DEFAULT_CURRENCY = CurrencyEnum.THB;

export enum CategoryEnum {
  Hot = 'hot',
  Casino = 'casino',
  Gaming = 'gaming',
  Sport = 'sport',
  Game = 'game',
}

export enum ProviderEnum {
  AfbCasino = 'afb-casino',
  SexyGaming = 'sexy-gaming',
  CtGaming = 'ct-gaming',
  RcgGames = 'rcg-games',
  PragmaticPlay = 'pragmatic-play',
  AfbGaming = 'afb-gaming',
  SaGaming = 'sa-gaming',
  KingMaker = 'king-maker',
  WmCasino = 'wm-casino',
  MancalaGaming = 'mancala-gaming',
  PgGaming = 'pg-gaming',
  PokGaming = 'pok-gaming',
  PlaystarGaming = 'playstar-gaming',
  Obet33 = 'obet33',
  SmartSoft = 'smart-soft',
}

export enum AgentEnum {
  Ohmvnd = 'ohmvnd',
  L68 = 'l68bet',
}

export enum ContactTypeEnum {
  Telegram = 'Telegram',
  FacebookPage = 'Facebook Page',
  FacebookGroup = 'Facebook Group',
  Line = 'Line Official Account',
  Youtube = 'Youtube',
  Discord = 'Discord',
}

export enum RegionEnum {
  US = 'us',
  VN = 'vn',
  TH = 'th',
}

export enum HomepageMenuEnum {
  Casino = 'casino',
  Gaming = 'gaming',
  Sport = 'sport',
  Lotto = 'lotto',
  Reward = 'reward',
  Announcement = 'announcement',
  Contact = 'contact',
}

export enum HomepageMenuTypeEnum {
  TypeImage = 'image',
  TypeIcon = 'icon',
}

export const ThemeImageLightPreset = {
  providerIcon: 'logo',
};

export const ThemeImageDarkPreset = {
  providerIcon: 'logoDark',
};
